import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const SecondChanceTerms = () => {
  return (
    <Layout>
      <SEO
        title="Second Chance Terms & Conditions"
        description="Read the Terms & Conditions for the Second Chance program."
      />

      <div className="sas">
        <div className="sas__section">
          <div className="sas__container">
            <center>
              <h1>OFFICIAL TERMS &amp; CONDITIONS</h1>
            </center>{" "}
            <br />
            <p>
              <strong>
                NO PURCHASE OR PAYMENT OF ANY KIND IS NECESSARY. A PURCHASE OR
                PAYMENT WILL NOT INCREASE YOUR CHANCES OF BEING SELECTED.
              </strong>
            </p>
            <p>
              <strong>
                OPEN ONLY TO ELIGIBLE LEGAL RESIDENTS OF THE UNITED STATES OF
                AMERICA WHO RESIDE IN OR NEAR OKLAHOMA COUNTY, OKLAHOMA, AND WHO
                ARE AT LEAST 18 YEARS OLD AS OF THE DATE OF APPLYING. THIS
                PROGRAM IS VOID WHERE PROHIBITED.
              </strong>
            </p>
            <p>
              <strong>
                READ THESE OFFICIAL TERMS &amp; CONDITIONS CAREFULLY AS YOU WILL
                BE BOUND BY THEM.
              </strong>
            </p>
            <p>
              <strong>1. ELIGIBILITY:</strong> The “Second Chance Program”
              (“Program”) is open only to legal residents of the United States
              of America, who reside in or near Oklahoma County, Oklahoma, and
              who are at least eighteen (18) years old as of the date of
              application. The Program is void where prohibited. Officers,
              directors, and employees (and members of their household or
              immediate family, i.e., spouse, parent, child, sibling,
              grandparent, the “steps” of each and members of each of their
              households) of Oral Surgery Specialists of Oklahoma (“Sponsor”),
              Sponsor, and each of their respective parent companies,
              affiliates, subsidiaries, promotion, marketing, advertising,
              digital and web design agencies (collectively, “Program
              Entities”), and other individuals or entities associated with the
              creation, administration, implementation, and execution of this
              Program are ineligible to apply. In order to maintain eligibility
              throughout the Program, an Applicant must abide by these Official
              Terms &amp; Conditions and maintain appropriate decorum befitting
              the reputation and values of Sponsor and must not otherwise engage
              in any conduct which would embarrass Sponsor or bring Sponsor into
              public disrepute or ridicule, or which would insult or offend the
              public or any segment thereof.
            </p>
            <p>
              <strong>2. HOW TO APPLY:</strong> Between 9/06/2023 at 9:00 AM, CT
              and 10/6/2023 at 11:59 PM, CT (“Submission Period”), an Applicant
              must follow the instructions to complete the required registration
              information, including his/her name, year of birth, valid email
              address, telephone number, and city and state of residence.
              Additionally, at such time, an Applicant must then follow the
              instructions to submit the required responses (“Responses”) as
              further described in Section 3 below (collectively, the
              “Submission”).<strong> </strong>If desired, applications can be
              submitted by visiting the Sponsor’s location, filling out a paper
              form, and returning it to Sponsor in person or by mail to 4320
              McAuley Blvd, Oklahoma City, OK 73120. All Submissions must be
              received by 10/06/2023 at 11:59 PM, CT.
            </p>
            <p>
              <strong>Limit: </strong>Only one (1) Submission per Applicant
              during the Program regardless of the email address used.
              Submissions received from any person, email address, or household
              in excess of the stated limit will be void. Applicants may only
              use one (1) email address to apply for the Program.
            </p>
            <p>
              <strong>NOTE: </strong>Once a Submission has been submitted, it
              may not be deleted, edited, or modified. All Submissions become
              the property of the Sponsor and will not be returned.
            </p>
            <p>
              <strong>3. SUBMISSION REQUIREMENTS:</strong> In order to be
              eligible to participate in the Program, an Applicant must submit a
              Submission consisting of three (3) Responses and all of which are
              to comply with the requirements stated below:
            </p>
            <p>
              <br />
              An Applicant must provide a Response in answer to each of the
              three (3) questions below.{" "}
            </p>
            <p>The Applicant must:</p>
            <ol>
              <li>
                Tell us about your dental history, including who your dentist is
                (if you have one), the date of your last dental visit, and how
                you heard about the Second Chance Program;
              </li>
              <li>Tell us why restoring your smile is important to you; and</li>
              <li>
                Tell us why you think you are a good candidate for our Second
                Chance Program and should be selected to receive the appropriate
                dental treatment, i.e., a single full-arch (either upper or
                lower) or a double full-arch (top and bottom) dental restoration
                (“Dental Procedure”).
              </li>
            </ol>
            <p>
              <strong>
                (B) Additional Applicable Submission Requirements:
              </strong>
            </p>
            <p>
              <br />
              The Submission, in its entirety:
            </p>
            <ol>
              <li>
                Must be the Applicant’s original creation (an original creation
                is the product of one’s own mind, is not a copy or imitation,
                and is not the result of trade secret misappropriation);
              </li>
              <li>Must be entirely in English; </li>
              <li>
                Must not include hyperlinks to content on other sites or media;{" "}
              </li>
              <li>
                Must not have been previously submitted in any Program or
                promotion or published in any media;
              </li>
              <li>
                Must not contain content that is unlawful, in violation of, or
                contrary to federal, state, or local laws;{" "}
              </li>
              <li>
                Should not contain material that, either explicitly or
                implicitly, mentions, depicts, shows, refers, or otherwise
                alludes to the name or logo, trademark, or service mark of any
                third party, individual, company, entity, product, or brand
                other than Sponsor’s.{" "}
              </li>
              <li>
                Must not contain any material or references that violate or
                infringe upon a third party’s rights, including but not limited
                to privacy, publicity, or intellectual property rights, nor may
                it in any way infringe upon a third party’s copyright or
                trademark rights;
              </li>
              <li>
                Must be in good taste as determined by Sponsor in their sole
                discretion;
              </li>
              <li>
                Must not contain language or contents that in any way disparages
                or defames or in any way reflects negatively upon the Sponsor,
                their practice or products, or any person or entity as
                determined by Sponsor in their sole discretion;
              </li>
              <li>
                Must not contain content that is inconsistent with Sponsor’s
                marketing practices;
              </li>
              <li>
                Must not contain, mention, refer, or otherwise allude to any
                obscene, lewd, violent, illegal, offensive, hateful,
                disparaging, or inappropriate words, language, symbols, signs,
                or abbreviations;{" "}
              </li>
              <li>
                Must not advocate or denigrate any political or societal
                beliefs;{" "}
              </li>
              <li>
                Must not contain, mention, refer, or otherwise allude to any
                political or religious references, statements, or symbols;
              </li>
              <li>
                Must not contain words or symbols that may be considered
                offensive to individuals of a certain race, ethnicity, religion,
                sexual orientation, age, or socioeconomic group; and
              </li>
              <li>
                Must not in any way promote alcohol, illegal drugs, or tobacco
                (or the use of any of the preceding).
              </li>
            </ol>
            <p>
              <strong>NOTE:</strong> Applicants understand and acknowledge that
              Sponsor, in their sole discretion, after good faith consideration
              and review of a Submission in its totality, reserves the right to
              accept, edit, modify, or reject a Submission. Additionally,
              Sponsor reserves the right at any time during the Program and
              thereafter to disqualify any Submission that Sponsor determines,
              in their sole discretion, does not comply with these Official
              Terms &amp; Conditions or otherwise contains, or uses, prohibited
              or inappropriate content, and, the Applicant may also be
              disqualified from participation in the Program.{" "}
            </p>
            <p>
              <strong>
                4. PROGRAM EVALUATION, SELECTION, AND VERIFICATION PROCESS:
              </strong>{" "}
              Only Submissions from eligible Applicants as defined in Section 1
              above whose Submissions are in compliance with Section 3 will be
              included for selection as stated herein. The Program will consist
              of two (2) rounds of evaluation. Evaluators referred to for each
              of the rounds (“Round One Evaluators” and “Round Two Evaluators”)
              may be referred to herein collectively as “Program Evaluators.”
            </p>
            <p>
              <strong>(A) ROUND ONE EVALUATION PERIOD: </strong>All eligible
              Entries received by Sponsor by 10/06/2023 at 11:59 PM, CT will be
              evaluated and assigned points by a panel of Program Round One
              Evaluators comprised of Sponsor’s designated administrative,
              marketing, and professional experts in accordance with the
              following criteria:{" "}
            </p>
            <p>
              Responses will be judged, evaluated and assigned a number of
              points based on the following:
            </p>
            <ol>
              <li>
                Applicant’s Response regarding Applicant’s dental health and why
                he/she wants/needs a dental treatment consisting of either a
                single (either upper or lower) or double (upper and lower)
                full-arch dental restoration (“Dental Treatment”) – 30 points;
              </li>
              <li>
                Applicant’s Response as to why restoring his/her smile is
                important to him/her – 35 points; and{" "}
              </li>
              <li>
                Applicant’s Response as to why Applicant thinks he/she is a good
                candidate for the Second Chance Program and should be selected
                to receive the appropriate dental treatment, i.e., a single
                full-arch (either upper or lower) or double full-arch (upper and
                lower) dental restoration (“Dental Procedure”) – 35 points.
              </li>
            </ol>
            <p>
              Round One Evaluation will take place on approximately 10/06/2023.
              Applicants must receive a minimum of a combined total of sixty
              (60) Round One Points from the Round One Evaluators in order to be
              deemed Semi-Finalists, subject to verification, and will be so
              notified by Sponsor or its representative on or about 10/13/2023.{" "}
            </p>
            <p> </p>
            <p>
              <strong>(B)</strong>{" "}
              <strong>
                ROUND TWO – SEMI-FINALIST EVALUATION PERIOD, SEMI-FINALIST
                RESPONSIBILITIES:{" "}
              </strong>
              Within approximately seven (7) business days after being notified
              of being a Semi-Finalist, all Semi-Finalists will receive from
              Sponsor the date(s) on which he/she is to appear at Sponsor’s
              offices for a medical/dental examination (approximate dates will
              be between 10/16/2023 and 10/20/2023) and a potential Interview
              (approximate dates will be between 10/23/2023 and 10/27/2023) with
              Sponsor and its representatives (“Interview &amp; Examination”),
              which Sponsor may record or videotape. The medical Examination and
              the Interview are typically held on separate days. At the time of
              such Interview &amp; Examination, Semi-Finalists will be required,
              among other things:
            </p>
            <p> </p>
            <ol>
              <li>
                To read, sign, and return to Sponsor at the time of his/her
                Interview &amp; Examination an Affidavit of
                Eligibility/Compliance &amp; Publicity/Liability Release
                (“Affidavit &amp; Release”). Failure to properly sign and return
                said Semi-Finalist Affidavit &amp; Release may result in
                disqualification of the potential Semi-Finalist;{" "}
              </li>
              <li>
                To complete and furnish Sponsor with a medical/dental history
                questionnaire as requested and provided by Sponsor, and
              </li>
              <li>
                To allow Sponsor and/or its professional representatives to
                conduct a complete clinical dental examination as they determine
                necessary, including but not limited to all X-rays of an
                Applicant’s teeth and mouth. All travel and out-of-pocket
                expenses incurred for a potential Semi-Finalist to attend and or
                participate in such meetings, interviews, medical/dental
                examinations shall be solely the responsibility of each
                Semi-Finalist;
              </li>
              <li>
                And to promptly comply with all other reasonable requests of
                Sponsor.
              </li>
            </ol>
            <p>
              Failure to comply with the above requirements will eliminate a
              Semi-Finalist from participation in the Program. Semi-Finalists
              acknowledge that they have been informed that their Interview(s)
              will be recorded and videotaped for evaluation purposes.
            </p>
            <p>
              <strong>
                SELECTION OF THE RECIPIENT OF THE DENTAL PROCEDURE:
              </strong>{" "}
              On or about 11/14/2023, the Round Two Evaluators, comprised of
              Sponsor’s designated administrative, marketing, and professional
              experts, will, in order to determine the potential recipient of
              the Dental Treatment (“Recipient”), i.e., the Semi-Finalist who
              would most benefit from receiving the single or double full-arch
              dental restoration treatment,{" "}
            </p>
            <ol>
              <li>
                Review each of the Semi-Finalist’s dental and medical records;
              </li>
              <li>
                Review the results of each Semi-Finalist’s clinical dental
                examination;{" "}
              </li>
              <li>Assess each Semi-Finalist’s suitability for treatment; </li>
              <li>
                Conduct and Assess each Semi-Finalist’s videotaped interview;
                and
              </li>
              <li>
                Review the Responses that each Semi-Finalist submitted during
                Round One.
              </li>
            </ol>
            <p>
              <a id="_gjdgxs"></a>The name of the potential Recipient will be
              determined on or about 12/2/2023 and Sponsor will notify the
              potential Recipient either by email and/or telephone at such time.
              The decisions of the Program Evaluators and Sponsor shall be final
              and binding in all matters pertaining to the evaluation and
              selection process. Potential recipients acknowledge that they will
              be the subject of a background check by Sponsor or their
              representative so that their selection as the Recipient will not
              embarrass or in any way reflect negatively on Sponsor or the
              Program and that they agree to the performance of said background
              check.{" "}
            </p>
            <p>
              <strong>5. “THE DENTAL PROCEDURE”: </strong>The Recipient will
              receive, as the Dental Procedure, treatment dependent upon his/her
              dental needs, as determined by the Round Two Evaluators in their
              best professional judgment, either a single full-arch (upper or
              lower) or double full-arch (upper and lower) dental implant
              replacement treatment. Approximate Retail Value (“ARV”): $25,000
              to $50,000, depending on the treatment provided. All expenses not
              specified above, such as travel to and from Sponsor’s professional
              offices for all meetings, examinations, dental appointments
              including full-dental arch restoration treatments, meals, etc.,
              are solely the Recipient’s responsibility. Sponsor has no
              responsibility for the Recipient’s inability or failure to accept
              or utilize the Dental Procedure as described herein. NOTE: In
              order to be awarded the Dental Procedure, the Finalist must
              dutifully keep all appointments at times mutually agreed to by the
              Recipient and Sponsor and otherwise cooperate and follow Sponsor’s
              medical/dental instructions, or the Dental Procedure will be
              deemed forfeited without any further responsibility by Sponsor to
              the Recipient. In addition to the previously signed Semi-Finalist
              Affidavit &amp; Release, the potential Recipient may be asked to
              sign a Recipient Affidavit &amp; Release as provided by Sponsor.
              Failure to sign and return the Recipient Affidavit &amp; Release
              by the date specified in the letter of notification may result in
              forfeiture of the Dental Procedure. The Recipient may be issued a
              Form 1099 for tax purposes in the amount of the actual value of
              the dental services provided and must submit his/her Social
              Security Number or Taxpayer ID Number, as required by law. The
              Dental Procedure is not redeemable for cash, nor is it
              transferable or substitutable except by Sponsor with one of equal
              or greater value. All federal, state, and local taxes are solely
              the responsibility of the Recipient.
            </p>
            <p>
              <strong>6. RESPONSIBILITIES OF ENTRANT:</strong>
            </p>
            <p>
              <strong>(A)</strong>{" "}
              <strong>
                REPRESENTATIONS/WARRANTIES AS CONDITIONS OF ENTRY/PARTICIPATION
                IN PROGRAM:
              </strong>{" "}
              By participating in this Program, an Applicant represents and
              warrants that: (i) he/she is of the legal capacity to participate
              in this Program; (ii) he/she owns all rights and title to his/her
              Submission, and the Submission is solely his/her own original
              work; (iii) the Submission does not infringe the intellectual
              property, privacy, publicity rights or any other legal or moral
              rights of any third party. Additionally, an Applicant represents
              and warrants that throughout his/her participation in this
              Program, he/she will conduct himself/herself in an appropriate
              manner and not engage in any behavior or manner that would
              embarrass Sponsor, Program Entities, or reflect negatively on
              Sponsor’s practice, service or products.
            </p>
            <p>
              <strong>(B) GRANTS/WAIVERS:</strong> By submitting a Submission,
              an Applicant assigns to Sponsor, their affiliates, legal
              representatives, assistants, agents, and licensees an irrevocable,
              exclusive, unlimited, unconditional, royalty-free, fully paid-up
              and perpetual license to copy, transmit, publish, post, broadcast,
              display, adapt, exhibit, reproduce, encode, store and/or otherwise
              use, reuse, or not use his/her Submission or any portion of the
              Submission, as submitted or as edited/modified in any way by the
              said licensees, in their sole discretion in the Program in any and
              all media without limitation, worldwide and throughout perpetuity,
              without additional compensation, notice to or approval from the
              Applicant. Applicant understands and agrees that although he/she
              has granted the above stated licensee to use or reuse his/her
              Submission or any portion thereof as stated herein above, Sponsor
              in its sole discretion, may elect not to so use said Submission.
              THE APPLICANT REPRESENTS, UNDERSTANDS, AND ACKNOWLEDGES THAT
              HE/SHE WILL NOT BE PAID FOR OR RECEIVE ANY FORM OF COMPENSATION OR
              ROYALTY IN EXCHANGE FOR GRANTING SPONSOR THESE RIGHTS OR FOR ANY
              SUBSEQUENT USE OF SUCH SUBMISSION OR ANY PORTION THEREOF BY
              SPONSOR OTHER THAN ANY DENTAL TREATMENT HE/SHE MAY RECEIVE HEREIN.
              The Applicant waives all intellectual property rights,
              privacy/publicity rights, or other legal or moral rights that
              might limit or preclude Sponsor’s use of the Submission or any
              portion thereof, or use of any recordings, photos, or videos taken
              during the Interview &amp; Examination, Dental Procedure, or
              follow-up interviews and appointments and agrees not to sue or
              assert any claim against Program Entities arising out of or
              connected to the use of any of the aforementioned or any portion
              thereof.
            </p>
            <p>
              (<strong>C) INDEMNIFICATION/RELEASE:</strong> By submitting a
              Submission, the Applicant agrees to indemnify and hold harmless
              the Program Entities from any and all claims, damages, expenses,
              costs (including reasonable attorneys' fees) and liabilities
              (including settlements) brought or asserted by any third party
              against any of them due to, or arising out of, the Submission or
              his/her conduct in creating the Submission or other actions in
              connection with this Program including, but not limited to, claims
              for trademark infringement, copyright infringement, work product,
              violation of an individual’s right of publicity or right of
              privacy, or defamation. The Applicant further agrees to release
              and hold harmless the Program Entities from any and all claims
              that any advertising subsequently produced, presented, and/or
              prepared by or on behalf of Sponsor infringes his/her rights with
              regard to the Submission and/or any portion thereof.{" "}
            </p>
            <p>
              <strong>7. INTERNET/LIMITATIONS OF LIABILITY:</strong> No
              liability or responsibility is assumed by Sponsor or Program
              Entities resulting from an individual’s participation in or
              attempt to participate in the Program. The Program Entities are
              not responsible for interrupted or unavailable network servers or
              other connections; for miscommunications; failed telephone or
              computer transmissions; for jumbled, scrambled or misdirected
              entries or transmissions; for phone, electrical, network, computer
              hardware or software or program malfunctions, failures or
              difficulties; for other errors, omissions, interruptions or
              deletions of any kind, whether human, mechanical, or electronic;
              or for any damage to any person's computer related to
              participating in the Program. The Program Entities are not
              responsible for incorrect or inaccurate information, whether
              caused by Internet users or by any of the equipment or programming
              associated with or utilized in the Program; or for any technical
              or human errors that may occur in the processing of any
              information in the Program. Persons found tampering with or
              abusing any aspect of the Program, as solely determined by
              Sponsor, may be disqualified and may be subject to prosecution.
              Any person attempting to apply using multiple email addresses,
              multiple identities, or any other device or artifice to apply
              multiple times or to interfere with the proper play of the Program
              may be disqualified from participation in the Program.
              Additionally, any use of robotic, automatic, macro, programmed,
              third-party, or like methods to participate in the Program will
              void any attempted participation affected by such methods and may
              lead to the disqualification of the individual utilizing the same.
              Sponsor reserves the right to disqualify any individual found, in
              its sole opinion, to be tampering with the operation of the
              Program, to be acting in violation of these Official Terms &amp;
              Conditions or to be acting with the intent to disrupt the intended
              operation of the Program. If Sponsor, in their sole discretion,
              determines that the Program is not or may not be capable of
              running as intended because of viruses, bugs, non-authorized human
              intervention, an insufficient number of Applicants,
              Semi-Finalists, or the Recipient not being in compliance with
              these Official Terms &amp; Conditions, or any other causes that
              corrupt or interfere or may corrupt or interfere with the
              administration, security, fairness, or proper play and/or intended
              execution of the Program, Sponsor reserves the right, in its sole
              discretion, to modify, suspend, cancel, or terminate the Program
              and proceed in a manner deemed fair and appropriate by Sponsor.{" "}
            </p>
            <p>
              <strong>
                CAUTION: ANY ATTEMPT TO DELIBERATELY DAMAGE THE WEBSITE OR ANY
                RELATED WEBSITE OR UNDERMINE TO THE LEGITIMATE OPERATION OF THE
                PROGRAM IS A VIOLATION OF CRIMINAL AND CIVIL LAWS. SHOULD SUCH
                AN ATTEMPT BE MADE, SPONSOR RESERVES THE RIGHT TO SEEK DAMAGES
                OR OTHER REMEDIES FROM ANY SUCH PERSON(S) RESPONSIBLE FOR THE
                ATTEMPT TO THE FULLEST EXTENT PERMITTED BY LAW.{" "}
              </strong>
            </p>
            <p>
              <strong>8. PRIVACY POLICY: </strong>Any personally identifiable
              information collected during an Applicant’s participation in this
              Program will be collected by Sponsor or their representative and
              used by Sponsor for purposes of the proper administration and
              fulfillment of the Program as described in these Official Terms
              &amp; Conditions and in accordance with the requirements of the
              Health Insurance Portability and Accountability Act.
            </p>
            <p>
              <strong>9. PUBLICITY RIGHTS: </strong>By participating in this
              Program, an Applicant agrees to allow Sponsor’s designees the
              perpetual right to use his/her name, biographical information,
              photos and/or likeness, videos and statements for Program, trade,
              commercial, advertising, and publicity purposes, at any time or
              times, in all media now known or hereafter discovered, worldwide,
              including, but not limited to, on the World Wide Web and Internet,
              without notice, review, or approval and without additional
              compensation except where prohibited by law.{" "}
            </p>
            <p>
              <strong>10. RELEASE: </strong>By participating in this Program,
              each Applicant releases and agrees to indemnify and hold harmless
              Program Entities from and against any and all costs, claims,
              damages (including without limitation, any special, incidental or
              consequential damages) or any other injury, whether due to
              negligence or otherwise, to person(s) or property (including,
              without limitation, death or violation of any personal rights,
              such as violation of right of publicity/privacy, libel or
              slander), due in whole or in part, directly or indirectly, to
              participation in the Program or acceptance of the Dental
              Procedure, or arising out of participation in any Program-related
              or Dental Procedure activity whether hosted or provided by Sponsor
              or a third party.
            </p>
            <p>
              <strong>11. DISPUTE RESOLUTION: </strong>Except where prohibited,
              by participating in the Program, an Applicant agrees that: (a)
              except as otherwise specifically set forth in these Official Terms
              &amp; Conditions, any action arising out of or relating to these
              Official Terms &amp; Conditions or the rights and obligations of
              any Applicant and/or Sponsor shall be filed exclusively in the
              United States Courts for the Western District of Oklahoma,
              Oklahoma County District Court, or the appropriate Oklahoma State
              Court located in Oklahoma County, Oklahoma, and Applicant hereby
              consents and submits to the personal jurisdiction of such court
              for the purposes of litigating any such action, and any right to a
              trial by jury is hereby waived; (b) any and all disputes, claims,
              and causes of action arising out of or connected with these
              Official Terms &amp; Conditions, and/or the rights and obligations
              of any Applicant and/or Sponsor, shall be resolved individually,
              without resort to any form of class action; (c) any and all
              claims, judgments, and awards shall be limited to actual
              out-of-pocket costs incurred and in no event will Applicant be
              entitled to receive attorneys’ fees or other legal costs; (d)
              under no circumstances will Applicant be permitted to obtain
              injunctive or other equitable relief and Applicant’s sole remedy
              will be an action at law for damages to the extent allowed by
              sub-paragraphs (c) and (e) of this Section 11; and (e) under no
              circumstances will Applicant be permitted, and Applicant hereby
              waives all rights, to: (i) claim punitive, exemplary, special,
              incidental, indirect, and consequential damages, and any other
              damages (whether due to negligence or otherwise) other than for
              actual out-of-pocket costs described in (c) above; and (ii) have
              damages multiplied or otherwise increased. All issues and
              questions concerning the construction, validity, interpretation,
              and enforceability of these Official Terms &amp; Conditions, or
              the rights and obligations of any Applicant and/or Sponsor, shall
              be governed by and construed in accordance with the laws of the
              Commonwealth of Oklahoma without giving effect or regard to any
              principles or doctrines of conflicts of law of the Commonwealth of
              Oklahoma or any other jurisdiction. If any provision or provisions
              of these Official Terms &amp; Conditions shall be held to be
              invalid, illegal, unenforceable, or in conflict with the law of
              any jurisdiction, the validity, legality and enforceability of the
              remaining provisions shall not in any way be affected or impaired
              thereby.{" "}
            </p>
            <p>
              <a id="_30j0zll"></a>
              <strong>12. GENERAL: </strong>Each Applicant waives any right to
              claim ambiguity in these Official Terms &amp; Conditions. All
              Submissions become property of Sponsor and will not be returned.
              In the event of a dispute as to the identity of a selected
              participant based on an email address, the selected participant
              entry will be declared made by the person identified in the
              registration upon applying. All federal, state, and local laws
              apply. The Program is void in jurisdictions other than those
              stated above and wherever prohibited.{" "}
            </p>
            <p>
              <strong>13. IMPORTANT: </strong>This Program is not in any manner
              sponsored, endorsed, administered by, or associated with Meta or
              any other platform. Each Applicant understands that they are
              providing their information to Sponsor and their designees and not
              to Meta or any other platform. The information an Applicant
              provides will only be used as stated in these Official Terms &amp;
              Conditions. By participating in the Program, each Applicant
              releases and agrees to indemnify Meta or any other platform and
              hold it harmless from and against any and all costs, claims,
              damages (including, without limitation, any special, incidental or
              consequential damages) or any other injury, whether due to
              negligence or otherwise, to person(s) or property (including,
              without limitation, death or violation of any personal rights,
              such as violation of right of publicity/privacy, libel, or
              slander), due in whole or in part, directly or indirectly, to
              participation in the Program or arising out of the receipt,
              use/misuse of or participation in any Program-related or Dental
              Procedure activity.{" "}
            </p>
            <p>
              <strong>14. NAME OF RECIPIENT: </strong>To obtain the name of the
              Recipient, send a properly stamped self-addressed envelope,
              postmarked by 10/06/2023, to Second Chance Recipient List, Oral
              Surgery Specialists of Oklahoma.
            </p>
            <p>
              <strong>SPONSOR: </strong>Oral Surgery Specialists of Oklahoma
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default SecondChanceTerms
